.App {
  text-align: center;

}

.pic{max-width:26%;border-radius: 10px;}
.texte{line-height:3;}
.footer {
  text-align: center;
  margin: 10px;
  line-height: 1.5;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

 
.status-indicator:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin-right: 8px; /* Espace entre le point et le texte */
}

.status-text {
  font-size: 16px;
  color: #333;
}




.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*CSS Ludo*/

.header{background-color: #00e5ff;padding:10px 5px;}
#nom {font-family: sans-serif;}
#titre{font-family: monospace;}
body{margin : 0% 2%;
min-width: 450px;
}

nav a {
  margin-right: 20px; /* ajustez cette valeur comme vous le souhaitez */
}
.texte {
  margin-bottom: 10px; /* ou tout autre valeur qui vous convient */
}
button,#custom{border-radius:20px; padding:10px;margin :5px;border-color: #00e5ff; }

/* ... Vos autres styles ... */

.timeline-container {
    margin: 20px;
}

.timeline-event {
    display: flex;
    margin-bottom: 20px;
}

.timeline-date {
    width: 110px;
    text-align: right;
    margin-right: 10px;
    border-right: 2px solid #000;
    padding-right: 10px;
    position: relative;
}

.timeline-date::after {
    content: "";
    position: absolute;
    right: -7px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #000;
    border: 2px solid #fff;
}

.timeline-content {
    flex: 1;
}

.image-gallery img {
  margin-right: 10px;
  width: 450px; /* ou la largeur que vous préférez */
}

h1.titre {
  text-align: center;
}
/* 77777 */
/* Styles pour les écrans de taille inférieure à 600 pixels de largeur */
/* @media screen and (min-width: 1200px) {
  body{margin : 0% 2%;
     }
  
 } 
*/

